<template>
  <PageWithLayout>
    <search
      :searchDataList.sync="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"/>
    <template v-if="viewModel.hasResult && viewModel.isSearched">
      <TableView v-for="(resultItem, index) in viewModel.dataList" :key="`${resultItem.approveNumber}_${index}`">
        <template v-slot:tbl_colgroup>
          <col style="width:190px"/>
          <col/>
          <col style="width:190px"/>
          <col/>
        </template>
        <template v-if="index === 0" v-slot:tbl_top>
          <div class="area_left">
            <strong class="tit_tbl">회원 검색결과</strong>
          </div>
        </template>
        <template v-slot:tbl_body>
          <tr>
            <th>승인번호</th>
            <td>
              <span class="txt_tbl">{{ resultItem.approveNumber }}</span>
            </td>
            <th>상태</th>
            <td>
              <span class="txt_tbl">{{ resultItem.status }}</span>
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td>
              <span class="txt_tbl">{{ resultItem.name }}</span>
            </td>
            <th>퍼핀 회원 ID</th>
            <td>
              <span class="txt_tbl">{{ resultItem.firfinUserUid }}</span>
            </td>
          </tr>
          <tr>
            <th>거래타입</th>
            <td>
              <span class="txt_tbl">
                <Badge
                  :text="resultItem.transactionType | convertIdToText('cs_card_transaction_type')"
                  :badgeStyle="resultItem.transactionType | convertIdToColor('cs_card_transaction_type')"
                  :badgeFill="false" />
              </span>
            </td>
            <th>업종코드</th>
            <td>
              <span class="txt_tbl">{{ resultItem.affiliateCode }}</span>
            </td>
          </tr>
          <tr>
            <th>가맹점번호</th>
            <td>
              <span class="txt_tbl">{{ resultItem.affiliateNumber }}</span>
            </td>
            <th>가맹점명</th>
            <td>
              <span class="txt_tbl">{{ resultItem.affiliateName }}</span>
            </td>
          </tr>
          <tr>
            <th>거래금액</th>
            <td>
              <span class="txt_tbl">{{ resultItem.transactionAmount | convertNumberToComma(false,'0원', '원') }}</span>
            </td>
            <th>거래일시</th>
            <td>
              <span class="txt_tbl">{{ resultItem.transactionAt | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
            </td>
          </tr>
          <tr>
            <th>설명</th>
            <td colspan="3">
              <span class="txt_tbl">{{ resultItem.description }}</span>
            </td>
          </tr>
          
        </template>
      </TableView>
    </template>
    <div v-if="viewModel.isSearched" class="area_empty">
      <p class="desc_empty">{{ viewModel.hasResult ? '검색결과가 없습니다' : '은행정보를 검색해주세요' }}</p>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
// viewModel
import CsUserByApproveViewModel from '@/views/cs/user/byapprove/viewModel/CsUserByApproveViewModel'

export default {
  name: 'CsUserByApprove',
  components:{
    PageWithLayout,
    Search,
    TableView,
    Badge
  },
  data(){
    return{
      viewModel: new CsUserByApproveViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tbl_comm table{min-width:100%;width:auto}
.board_comm::v-deep .tbl_comm table .txt_tbl{display:block;max-width:200px}
</style>