import router from '@/router';
import { convertIdToText } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class CsUserByBankViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'결제승인번호',
        type: 'Input',
        grid: 100,
        maxWidth:'300px',
        value:'',
      },
      {
        title:'결제일',
        type: 'DatePicker',
        grid: 100,
        maxWidth:'300px',
        value:'',
      },
    ]
    this.isSearched = false;
    this.hasResult = false;
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  onSearch(){
    this.getData();
  }
  getData(){
    this.isSearched = false;
    this.dataList = [];
    if(!this.searchDataList[0].value || !this.searchDataList[1].value){
      store.dispatch('commonToast/fetchToastStart', `결제정보를 정확하게 검색해주세요`);
      return
    }
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.CS_USER_BY_APPROVED.format(this.searchDataList[0].value,this.searchDataList[1].value)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.isSearched = true;
      const resultData = success.data;
      this.dataList = resultData;
      this.hasResult = resultData && resultData.length > 0;
    }, (failed) => {
      this.dataList = [];
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}